import * as React from 'react'
import { fontSize } from '../css/mixins/mixins'

import styled, { keyframes } from 'styled-components'
const fadeout = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`
const ImageWrap = styled.section`
  background-color: #372c2e;
  position: relative;
  overflow: hidden;
  min-height: 300px;
  box-shadow: 0px 6px 29px 1px rgba(0, 0, 0, 0.5);

  @media only screen and (min-width: 930px) {
    min-height: 600px;
  }
`

const Image = styled.div`
  opacity: 0.4;
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`

const Title = styled.h1`
  position: absolute;
  top: 50%;
  /* animation: 1s ${fadeout} forwards ease-out; */
  /* animation-delay: 2s; */
  left: 50%;
  color: #fff;
  min-width: 200px;
  text-align: center;
  padding: 15px 30px;
  transform: translate(-50%, -50%);
  font-weight: 300;
  background-color: transparent;
  width: 100%;
  max-width: 90%;
  ${fontSize(35)}

  @media only screen and (min-width: 930px) {
    left: calc(50% + 55px);
    ${fontSize(50)}
  }

  &::after {
    content: '';
    position: absolute;
    width: 50px;
    height: 2px;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
  }

  ${({ comingsoon }) =>
    comingsoon &&
    `
      &::before {
        content: "Coming Soon";
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%);
        color: #fff;
      }
    `}
`

const HeaderImage = ({ src, title = '', coming_soon_title = false }) => {
  return (
    <ImageWrap id="header-target">
      <Image style={{ backgroundImage: `url(${src})` }} />
      {title !== '' && <Title>{title}</Title>}
    </ImageWrap>
  )
}

export default HeaderImage
