import * as React from 'react'
import { graphql, Link } from 'gatsby'
import ReactMarkdown from 'react-markdown'

import Layout from '../components/sections/layout'
import Seo from '../components/sections/seo'
import HeaderImage from '../components/headerImage'

import styled from 'styled-components'
import { fontSize } from '../css/mixins/mixins'
const Breadcrumb = styled.div`
  background-color: whitesmoke;
  padding: 10px 0;
  margin-bottom: 60px;
`

const BreadcrumbLink = styled(Link)`
  text-decoration: none;
  ${fontSize(15)}
  transition: 0.3s color;

  &:hover {
    color: #3a3a3a;

    i {
      border-color: #3a3a3a;
    }
  }
`

const BreadcrumbArrow = styled.i`
  border: solid #917147;
  transition: 0.3s border-color;

  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  margin-right: 5px;
`

const Team = ({ data }) => {
  const { strapiTeam } = data
  const { title, description, main_image = require(`../media/images/basic_layout_placeholder_image_1.png`).default, SEO = {} } = strapiTeam

  const url = main_image !== null ? main_image?.url || main_image : require(`../media/images/basic_layout_placeholder_image_1.png`).default
  const { page_title = title, page_description = '' } = SEO

  return (
    <Layout>
      <Seo title={page_title} description={page_description} />
      <HeaderImage src={url} title={title} />
      <Breadcrumb>
        <div className="container">
          <BreadcrumbLink to="/about">
            <BreadcrumbArrow /> Back to the team
          </BreadcrumbLink>
        </div>
      </Breadcrumb>
      <div className="container">
        <ReactMarkdown children={description} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query teamQuery($slug: String = "") {
    strapiTeam(slug: { eq: $slug }) {
      title
      description
      SEO {
        page_title
        page_description
      }
      main_image {
        url
      }
    }
  }
`

export default Team
